<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardBody v-if="isLoading">
            <MbnSpinner />
          </CCardBody>

          <CCardBody v-if="error">
            <MbnErrorMessage />
          </CCardBody>

          <SiteForm v-if="site" :is-sending="isSending" :initial-values="initialValues" @formSubmit="onSubmit" />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { GET_SITE, EDIT_SITE } from '@/store/action-types/sites';
import SiteForm from './SiteForm';
import MbnSpinner from '@/components/Spinner';
import MbnErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'SiteEdit',
  components: {
    SiteForm,
    MbnSpinner,
    MbnErrorMessage,
  },
  computed: {
    ...mapState('sites', ['isLoading', 'isSending', 'error', 'site']),
    recordId() {
      return this.$route.params.id;
    },
    initialValues() {
      return {
        active: this.site.active,
        category: this.site.category,
        advertiserWhiteList: this.site.advertiserWhiteList,
        url: this.site.url,
        moderated: this.site.moderated,
      };
    },
  },
  mounted() {
    this.GET_SITE(this.recordId);
  },
  methods: {
    ...mapActions('sites', [GET_SITE, EDIT_SITE]),
    onSubmit(formData) {
      this.EDIT_SITE({ id: this.recordId, formData })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.updated'),
          });
          this.$router.push({ name: 'sites' });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
  },
};
</script>
