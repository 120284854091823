<template>
  <div>
    <CCardHeader>
      <CButton type="button" size="sm" color="info" @click="goBack"><CIcon name="cil-chevron-left" /></CButton>
    </CCardHeader>
    <CCardBody>
      <CForm id="submit" @submit.prevent="formSubmit">
        <CInput
          v-model.trim="formData.url"
          size="sm"
          :label="$t('sites.url')"
          :class="{ 'is-invalid': $v.formData.url.$error }"
        />
        <small v-if="$v.formData.url.$error" class="form-error">{{ $t('errors.field_required') }}</small>

        <CSelect
          custom
          size="sm"
          :label="$t('sites.category')"
          :value.sync="$v.formData.category.$model"
          :placeholder="$t('sites.category')"
          :options="siteCategories"
          :class="{ 'is-invalid': $v.formData.category.$error }"
        ></CSelect>
        <small v-if="$v.formData.category.$error" class="form-error">{{ $t('errors.field_required') }}</small>

        <CRow class="mb-2">
          <CCol>
            <label for="active" class="mb-2 col-form-label-sm">{{ $t('sites.active') }}</label>
            <CSwitch id="active" class="d-block" :checked.sync="formData.active" color="info" />
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
    <CCardFooter>
      <CButton form="submit" type="submit" :disabled="isSending" size="sm" color="info">{{ buttonText }}</CButton>
      <CButton class="ml-2" type="button" size="sm" color="danger" @click="goBack">{{ $t('ui.cancel') }}</CButton>
    </CCardFooter>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GET_SITE_CATEGORIES } from '@/store/action-types/dictionary';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'SiteForm',
  props: {
    isSending: {
      type: Boolean,
      required: true,
    },
    initialValues: {
      type: Object,
      required: true,
    },
  },
  validations: {
    formData: {
      category: {
        required,
      },
      url: {
        required,
      },
    },
  },
  data() {
    return {
      formData: {
        active: this.initialValues.active,
        category: this.initialValues.category,
        advertiserWhiteList: this.initialValues.advertiserWhiteList,
        url: this.initialValues.url,
        moderated: this.initialValues.moderated,
      },
    };
  },
  computed: {
    ...mapGetters('dictionary', ['siteCategories']),
    buttonText() {
      return this.isCreateForm ? this.$t('ui.create') : this.$t('ui.update');
    },
    isCreateForm() {
      return this.$route.name === 'site-create';
    },
  },
  mounted() {
    this.GET_SITE_CATEGORIES();
  },
  methods: {
    ...mapActions('dictionary', [GET_SITE_CATEGORIES]),
    goBack() {
      this.$router.go(-1);
    },
    formSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.$emit('formSubmit', this.formData);
    },
  },
};
</script>
